import React, { ReactElement, useState } from 'react';

import './survey-page.scss';
import SurveyForm from './survey-form/survey-form';
import { Question } from '../../models/question';
import PrivacyForm from './privacy-form/privacy-form';
import ResultForm from './result-form/result-form';
import SurveyHeader from '../../components/survey-header/survey-header';
import { CreatingAnswer, Score } from '../../models/answer';
import AnswerService from '../../services/answer-service';
import { useLocation, useNavigate } from 'react-router-dom';
import KakaoTalkService from '../../services/kakaotalk-service';


export default function SurveyPage(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();
    const [ step, setStep ] = useState<number>(0);
    const [ answer, setAnswer ] = useState<CreatingAnswer>({
        name: undefined,
        phoneNumber: undefined,
        birthDay: new Date(),
        createdDate: new Date(),
        choices: [],
        scores: [],
        resultType: undefined
    } as CreatingAnswer);

    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');

    function renderItem(items: any[]): ReactElement {
        return items[step];
    }

    function onPrivacyInfoEntered(name: string, telNumber: string, birthDay: Date) {
        setAnswer({
            ...answer,
            name,
            phoneNumber: telNumber,
            birthDay
        });
        setStep(step + 1);
    }

    function onQuestionAnswered(questionIndex: number, answerIndex: number) {
        const a = {
            ...answer
        };
        a.choices[questionIndex] = answerIndex;
        setAnswer(a);
        setStep(step + 1)
    }

    function onSubmitted() {
        const scoreMapping = [ 4, 3, 2, 1 ];
        const t = answer.choices.map(c => scoreMapping[c]);
        const scores: Score[] = [
            { type: 'A', score: t[0] + t[1] },
            { type: 'B', score: t[2] + t[3] },
            { type: 'C', score: t[4] + t[5] },
            { type: 'D', score: t[6] + t[7] },
            { type: 'E', score: t[8] + t[9] }
        ];

        const sortedScores: Score[] = scores.sort((a: Score, b: Score): number => {
            const r = b.score - a.score;
            if (r !== 0) {
                return r;
            }

            return a.type.charCodeAt(0) - b.type.charCodeAt(0);
        });

        const result: CreatingAnswer = {
            ...answer,
            scores: sortedScores,
            resultType: sortedScores[0].type
        };

        const service = new AnswerService();
        service.write(result).then(() => {
            navigate(`/result/${result.resultType}` + (from ? `?from=${from}` : ''));
        });

        const kakaoTalkService = new KakaoTalkService();
        kakaoTalkService.sendMessage(answer.phoneNumber!, answer.name!, sortedScores[0].type).then(a => {
        });
    }

    const questions: Question[] = [
        {
            title: 'Q1',
            description: '<p>อะไรทำให้ผิวหน้าของคุณแดง?</p>',
            answers: [
                'แดงง่ายกับการเปลี่ยนแปลงหรือสิ่งระคายเคืองเล็กน้อย, และใช้เวลานานกว่าจะหาย.',
                'แดงเร็วกับสิ่งระคายเคืองเล็กน้อย, แต่กลับสู่สภาพปกติเร็ว.',
                'แดงเฉพาะกับสิ่งระคายเคืองรุนแรง เช่น ถูขีดข่วน.',
                'หายากที่จะมีประสบการณ์ผิวแดง.'
            ]
        },
        {
            title: 'Q2',
            description: '<p>ลักษณะของผิวของคุณเป็นอย่างไร?</p>',
            answers: [
                'หยาบชัดเจน.',
                'หยาบเมื่อสัมผัส.',
                'ไม่หยาบทั้งทางสายตาและสัมผัส.',
                'เรียบและเนียน.'
            ]
        },
        {
            title: 'Q3',
            description: '<p>ผิวของคุณเป็นอย่างไรทันทีหลังการล้างหน้า?</p>',
            answers: [
                'ตึงทั่วหน้า.',
                'ตึงในบางพื้นที่ เช่น แก้ม, หน้าผาก.',
                'ดูโอเค แต่รู้สึกตึงภายใน.',
                'ไม่มีความตึงใด ๆ.',
            ]
        },
        {
            title: 'Q4',
            description: '<p>สภาพผิวของคุณหลังจากใช้สกินแคร์พื้นฐาน?</p>',
            answers: [
                'ยังคงแห้งหลังจากใช้.',
                'ดูโอเคแต่ไม่นานก็รู้สึกแห้ง.',
                'ความชุ่มชื้นอยู่ได้ 2-3 ชั่วโมง.',
                'ชุ่มชื้นและนุ่มทั้งวัน.',
            ]
        },
        {
            title: 'Q5',
            description: '<p>สถานะริ้วรอยบนใบหน้าเมื่อหน้าบึ้งหรือยิ้ม?</p>',
            answers: [
                'ริ้วรอยทั่วหน้า.',
                'ริ้วรอยใกล้ตา, ปากเมื่อเคลื่อนไหว.',
                'สังเกตเห็นได้ในบางส่วน, ริ้วรอยเล็กน้อย.',
                'แทบไม่มีริ้วรอย.',
            ]
        },
        {
            title: 'Q6',
            description: '<p>สถานะริ้วรอยบนใบหน้าเมื่อหน้าผ่อนคลาย?</p>',
            answers: [
                'ริ้วรอยยังคงอยู่ทั่วหน้า.',
                'ริ้วรอยยังคงอยู่ใกล้ตา, ปาก.',
                'ริ้วรอยเลือนหายเร็ว.',
                'เรียบเนียน, ไม่มีร่องรอย.',
            ]
        },
        {
            title: 'Q7',
            description: '<p>สภาพผิวประจำวันของคุณเป็นอย่างไร?</p>',
            answers: [
                'ตึงภายใน, มันภายนอก.',
                'รู้สึกแห้ง, มันบนผิว.',
                'เล็กน้อยแห้ง.',
                'ไม่แห้งหรือมัน.',
            ]
        },
        {
            title: 'Q8',
            description: '<p>คุณมีประสบการณ์การเกิดสิวบ่อยแค่ไหน?</p>',
            answers: [
                'บ่อย, ทุกๆไม่กี่วัน.',
                'ทุก 2-3 สัปดาห์.',
                'ทุกหลายเดือนหรือหลายปี.',
                'แทบไม่เคย.',
            ]
        },
        {
            title: 'Q9',
            description: '<p>ความถี่ของจุดเช่นฝ้าหรือจุดด่างดำ?</p>',
            answers: [
                'กระจายอย่างต่อเนื่อง.',
                'เป็นระยะ, ไม่นาน.',
                'หายาก, ทุกไม่กี่ปี.',
                'แทบไม่มี.',
            ]
        },
        {
            title: 'Q10',
            description: '<p>ระยะเวลาของรอยดำจากสิวหรือบาดแผล?</p>',
            answers: [
                '6 เดือนถึงมากกว่าหนึ่งปี',
                '1 ถึง 6 เดือน',
                'ภายใน 4 สัปดาห์',
                'ไม่มี.',
            ]
        }                
    ];

    const items = [
        <PrivacyForm
            name={answer.name}
            phoneNumber={answer.phoneNumber}
            birthDay={answer.birthDay}
            onEntered={onPrivacyInfoEntered}/>,
        ...(questions.map((q, qi) =>
            <SurveyForm question={q}
                        selected={answer.choices[qi]}
                        onAnswered={(ai) => onQuestionAnswered(qi, ai)}
                        onCanceled={() => setStep(step - 1)}
                        maxStepCount={questions.length + 1}
                        currentStep={qi + 1}/>
        )),
        <ResultForm name={answer.name!} onSubmitted={onSubmitted}/>
    ]

    return (
        <div className={'survey-page'}>
            <SurveyHeader closable={true} onClosed={() => {
                navigate(`/${from}`);
            }}/>
            <div className={'survey-page-content'}>{renderItem(items)}</div>
        </div>
    );
}
